import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import * as $ from 'jquery';


@Component({
    selector: 'cairo-chart',
    template: `
        <canvas></canvas>
    `,
    styles: [':host { width: 100%; height: 100%; }']
})
export class CairoChartComponent implements OnInit {
    private chart : any = [];

    constructor() { }
    ngOnInit() { }

    public load(wave: number[], selector: string = 'canvas') {
        this.chart = new Chart($(selector), {
            type: 'line',
            data: {
                datasets: [{
                    data: wave.map((item) => { return {x: item[0], y: item[1]} }),
                    fill: false,
                    pointRadius: 0,
                    borderWidth: 3,
                    borderColor: '#58585a'
                }]
            },
            options: {
                legend: { display: false },
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: "Wavelength(nm)",
                        },                        
                        type: 'linear',
                        position: 'bottom',
                        ticks: {
                            min: Math.min.apply(Math, wave.map(item => item[0])),
                            max: Math.max.apply(Math, wave.map(item => item[0])),
                        } 
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: "R(%)"
                        },
                        ticks: {
                            min: 0,
                            stepSize: 10
                        }                        
                    }]
                }
            }
        });
    }

    public add(wave: number[]) {
        this.chart.data.datasets[0].label = 'current';
        this.chart.data.datasets.push({
            label: 'target',
            data: wave.map((item) => { return {x: item[0], y: item[1]} }),
            fill: false,
            pointRadius: 1,
            borderWidth: 3,
            borderColor: '#579d1e'
        });
        this.chart.update();
    }
}