import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgProgressModule } from "ngx-progressbar";
import { CairoService } from './cairo.service';
import { FittingsComponent } from './fittings.component';
import { 
    CustomerSelectorComponent,
    MachineSelectorComponent,
    RecipeSelectorComponent, 
    SubstrateSelectorComponent,
    SpectrophotometerComponent,
    PairTableComponent,
    TableValuesComponent,
} from './fittings.controls.component';
import { CairoChartComponent } from './chart.component';


@NgModule({
  imports: [
    CommonModule,
    NgProgressModule,    
  ],
  entryComponents: [],  
  declarations: [
    FittingsComponent,
    CustomerSelectorComponent,
    MachineSelectorComponent,
    RecipeSelectorComponent,
    SubstrateSelectorComponent,
    SpectrophotometerComponent,
    PairTableComponent,
    TableValuesComponent,
    CairoChartComponent,
  ],
  exports: [
    FittingsComponent,
  ],
  providers: [
    CairoService,
  ]
})
export class FittingsModule { }