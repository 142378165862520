export const environment ={
  production: true,

  api: {
    cairo: 'https://cairo.horizonsoptical.com/production'
  },
  
  auth: {
    endpoint: 'https://auth.horizonsoptical.com',
    key: 'gM87QkxPWyrJJcVJ'
  }
};