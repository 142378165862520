import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app-view.component.html',
  styleUrls: ['./app-view.component.css']
})

export class AppViewComponent {
  private isLoggedIn: boolean = false;
  private option = 'home';

  constructor(
    private auth: AuthService
  ) {
    auth.onAuthChanged
    .subscribe(authState => {
      this.isLoggedIn = authState != null;
      if (!this.isLoggedIn) {
        this.option = 'home';
      }
    });
  }

  private navigation(option: string) {
    this.option = option;
  }
};
