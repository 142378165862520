import { 
  NgModule 
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TopbarComponent } from './topbar/topbar.component';
import { LoginComponent } from './topbar/login/login.component';
import { AppViewComponent } from './app-view.component';
import { FittingsModule } from './fittings/fittings.module';
import { AuthService } from './auth/auth.service';
import { 
  AuthHttpInterceptor, 
  provideTokenizedHttpClient,
} from './auth/httpclient.service';
import { HTTP_NOAUTH, } from './auth/httpclient.def';


@NgModule({
  entryComponents: [
  ],
  declarations: [
    AppViewComponent,
    TopbarComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FittingsModule,
  ],
  providers: [
    AuthService,
    provideTokenizedHttpClient(HTTP_NOAUTH, { excludes: [AuthHttpInterceptor] }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppViewComponent]
})
export class AppModule { }
