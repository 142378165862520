import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP_NOAUTH } from '../auth/httpclient.def';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators'
import { NgProgress } from "ngx-progressbar";


@Injectable()
export class CairoService {
    constructor(
        private auth: AuthService,
        @Inject(HTTP_NOAUTH) private http: HttpClient,
        private ngProgress: NgProgress
    ) { }

    public machines(customer: string) {
        return new Promise((resolve, reject) => {
            if (this.auth.access_token()) {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'X-Client-ID': customer,
                    })
                };

                this.http.get<any[]>(environment.api.cairo + "/machines", httpOptions)
                    .subscribe(
                        (machines: any[]) => {
                            resolve(machines);
                        },
                        error => { console.log(error); reject('request failed') }
                    );
            } else {
                reject('auth error');
            }
        });
    }

    public recipes(customer: string, machine: string) {
        return new Promise((resolve, reject) => {
            if (this.auth.access_token()) {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'X-Client-ID': customer,
                        'X-Machine-ID': machine,
                    })
                };

                this.http.get<any[]>(environment.api.cairo + "/recipes", httpOptions)
                    .subscribe(
                        (recipes: any[]) => {
                            resolve(recipes);
                        },
                        error => { console.log(error); reject('request failed') }
                    );
            } else {
                reject('auth error');
            }
        });
    }

    public substrates(customer: string, machine: string, recipe: string) {
        return new Promise((resolve, reject) => {
            if (this.auth.access_token()) {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'X-Client-ID': customer,
                        'X-Machine-ID': machine,
                    })
                };

                this.http.get<any>(environment.api.cairo + "/recipes/" + recipe, httpOptions)
                    .subscribe(
                        (info: any) => { resolve(info.substrates); },
                        error => { console.log(error); reject('request failed') }
                    );
            } else {
                reject('auth error');
            }
        });
    }

    public layers(customer: string, machine: string, recipe: string) {
        return new Promise((resolve, reject) => {
            if (this.auth.access_token()) {
                const httpOptions = {
                    headers: new HttpHeaders({
                        'X-Client-ID': customer,
                        'X-Machine-ID': machine,
                    })
                };

                this.http.get<any>(environment.api.cairo + "/recipes/" + recipe, httpOptions)
                    .subscribe(
                        (info: any) => { resolve(info.layers); },
                        error => { console.log(error); reject('request failed') }
                    );
            } else {
                reject('auth error');
            }
        });
    }



    spectrophotometer = {
        "shimadzu_rvprove": {
            id: "shimadzu_rvprove",
            name: "Shimadzu UVProbe",
            angle: 15,
            reference: "ED_mirror",
            format: {
                header: 2,
                separator: "\t",
                decimal: "."
            }
        },
        "shimadzu_ascii": {
            id: "shimadzu_ascii",
            name: "Shimadzu ASCII",
            angle: 15,
            reference: "Absolut",
            format: {
                header: 0,
                separator: ",",
                decimal: "."
            }
        },
        "uniwin": {
            id: "uniwin",
            name: "UNIWIN",
            angle: 15,
            reference: "absolut",
            format: {
                header: 2,
                separator: "\t",
                decimal: "."
            }
        },
        "filmetrics": {
            id: "filmetrics",
            name: "Filmetrics",
            angle: 0,
            reference: "absolut",
            format: {
                header: 1,
                separator: "\t",
                decimal: "."
            }
        },
        "shimadzu_tg": {
            id: "shimadzu_tg",
            name: "Shimadzu TG",
            angle: 15,
            reference: "TOG_mirror",
            format: {
                header: 2,
                separator: "\t",
                decimal: "."
            }
        },
        "filmetrics_tpx": {
            id: "filmetrics_tpx",
            name: "Filmetrics TPX",
            angle: 0,
            reference: "absolut100",
            format: {
                header: 1,
                separator: "\t",
                decimal: ","
            }
        },
        "shimadzu_mar": {
            id: "shimadzu_mar",
            name: "Shimadzu MAR",
            angle: 15,
            reference: "MAR_mirror",
            format: {
                header: 2,
                separator: "\t",
                decimal: "."
            }
        },
    }

    public spectrophotometers() {
        return Object.keys(this.spectrophotometer)
            .map(key => this.spectrophotometer[key])
    }

    public angle(spectrophotometer: string): number {
        if (this.spectrophotometer.hasOwnProperty(spectrophotometer)) {
            return this.spectrophotometer[spectrophotometer].angle;
        } else {
            return null;
        }
    }

    public reference(spectrophotometer: string) {
        if (this.spectrophotometer.hasOwnProperty(spectrophotometer)) {
            return this.spectrophotometer[spectrophotometer].reference;
        } else {
            return null;
        }
    }

    public parsing_info(spectrophotometer: string) {
        if (this.spectrophotometer.hasOwnProperty(spectrophotometer)) {
            return this.spectrophotometer[spectrophotometer].format;
        } else {
            return null;
        }
    }

    private Interval: number = 10000;
    private MaxSteps: number = 12; // 2min timeout


    public doJob(content: string): Promise<any> {

        return new Promise<any>((resolve) => {
            // post to service
            let url = environment.api.cairo + '/';

            let payload = btoa(content);
            this.http.post(url,
                JSON.stringify({ payload: payload, isBase64Encoded: true }),
                { observe: 'response' }
            ).subscribe(
                (resp => {
                    if ([202, 303].includes(resp.status)) {
                        const timer = interval(this.Interval);
                        const timeout = timer.pipe(take(this.MaxSteps));

                        const url = resp.headers.get('location');

                        const subscription = timeout.subscribe(() => {
                            this.http.get(url, { observe: 'response' })
                                .subscribe(
                                    (resp2 => {
                                        if (resp2.status == 200) {
                                            subscription.unsubscribe();
                                            resolve({
                                                status: true,
                                                body: resp2.body
                                            })
                                        } else if (resp2.status != 200) {
                                            this.ngProgress.inc(.15);
                                        }
                                    })
                                )
                        })
                    } else if (200 == resp.status) {
                        resolve({
                            status: true,
                            body: resp.body
                        })
                    }
                }),
                ((err) => {
                    console.log(err);
                    resolve({
                        status: false,
                        error: err
                    })
                })
            )
        });


    }
}