import {
    Component,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import * as $ from 'jquery';
import { CairoService } from './cairo.service';
import { AuthService } from '../auth/auth.service';
import { CairoChartComponent } from './chart.component';
import { NgProgress } from "ngx-progressbar";
import { 
    MachineSelectorComponent,
    RecipeSelectorComponent,
    SubstrateSelectorComponent,
    TableValuesComponent
} from './fittings.controls.component';

@Component({
    selector: 'app-fittings',
    templateUrl: './fittings.component.html',
    styleUrls: ['./fittings.component.css']
})
export class FittingsComponent implements AfterViewInit {
    private client: string;

    @ViewChild(MachineSelectorComponent, {static: false}) machineSelector !: MachineSelectorComponent;
    @ViewChild(RecipeSelectorComponent, {static: false}) recipeSelector !: RecipeSelectorComponent;
    @ViewChild(SubstrateSelectorComponent, {static: false}) substrateSelector !: SubstrateSelectorComponent;
    @ViewChild(TableValuesComponent, {static: false}) valuesComponent;

    constructor(
        private srv: CairoService,
        private auth: AuthService,
        private ngProgress: NgProgress,
    ) { 
        this.client = this.auth.shop_id();
    }

    ngAfterViewInit() {
        this.onCustomerSelection(this.client);
    }    

    private onCustomerSelection(customer: string) {
        this.client = customer;
        if (this.machineSelector) {
            this.machineSelector.load(this.client);
        }
    }

    private onMachineSelection(machine: string) {
        this.recipeSelector.load(this.client, machine);
    }

    private onRecipeSelection(recipe: string) {
        let machine = $('fittings-machine-selector select')[0].value as string
        this.substrateSelector.load(this.client, machine, recipe);
        this.valuesComponent.load(this.client, machine, recipe);
    }

    private pad(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    private generateJobInput(): string {
        let content = '';

        let machine = $('fittings-machine-selector select')[0].value;
        let recipe = $('fittings-recipe-selector select')[0].value;
        let substrate = $('fittings-substrate-selector select')[0].value;
        let spectrophotometer: string = $('fittings-spectrophotometer select')[0].value.toString();
        let angle = this.srv.angle(spectrophotometer);
        let reference = this.srv.reference(spectrophotometer);


        content += `machine\t${this.client}${machine}\n`;
        content += `recipe\t${this.client}${machine}_${recipe}\n`;

        content += `substrate\t${substrate}\n`;
        content += `angle\t${angle}\n`;
        content += `reference\t${reference}\n`;

        let layers = this.valuesComponent.get_layers();
        let idxLayer = 0;
        for (; idxLayer < layers.length; idxLayer++) {
            let value = layers[idxLayer];
            if (value) {
                content += `th${this.pad(idxLayer + 1, 2)}\t${value}\n`
            } else {
                content += `th${this.pad(idxLayer + 1, 2)}\tNA\n`
            }
        }
        for (; idxLayer < 15; idxLayer++) {
            content += `th${this.pad(idxLayer + 1, 2)}\tNA\n`
        }

        content += `wave\treflection\n`;
        this.wave.forEach(element => {
            content += `${element[0]}\t${element[1]}\n`;
        });

        return content;
    }

    private result = null;
    private target_wave = [];

    private calculate() {
        this.ngProgress.set(.05);

        this.srv.doJob(this.generateJobInput())
        .then((response) => {
            if (response.status) {
                this.result = response.body
                this.valuesComponent.show(this.result);
                
                if (this.result.target_curve) {
                    this.target_wave = this.result.target_curve.map((item) => {
                        return [item.x, item.y];
                    });
                    this.chartComponent.add(this.target_wave);
                }

                this.ngProgress.done();
            }
        })
        .catch((err) => {
            console.log(err);

            this.ngProgress.done();
        })   
    }

    private import() {
        $("#fileLoader").click();
    }

    private wave = [];
    @ViewChild('mainChart', {static: false}) chartComponent: CairoChartComponent;    

    private onFileSelected($event) {
        let files = $event.target.files;
        if (files.length == 1) {
            let reader = new FileReader();

            reader.onload = (e) => {
                // get parsing info
                let spectrophotometer: string = $('fittings-spectrophotometer select')[0].value.toString();
                let parsing_info = this.srv.parsing_info(spectrophotometer);

                let lines: string[] = reader.result.toString().split('\n');

                // remove header
                lines = lines.slice(parsing_info.header);

                // filter empty lines
                lines = lines.filter(line => line.length > 0);

                // split columns
                let dataStr = lines.map(item => item.split(parsing_info.separator));

                // parse numbers
                this.wave = dataStr.map((item) => {
                    return [
                        parseFloat(item[0].replace(',', '.')),
                        parseFloat(item[1].replace(',', '.'))
                    ];
                });

                this.chartComponent.load(this.wave, 'app-fittings canvas');
            }

            reader.readAsText(files[0]);
        }
    }
};