import { Component, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { AuthService } from '../auth/auth.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent {

  private currentOption: string = 'home';

  constructor(
    private auth: AuthService
  ) { }

  @Output() onNavigationEvent = new EventEmitter<string>();
  onNavigation($event) {
    let clickedElement = $event.target || $event.srcElement;
    this.onNavigationEvent.emit($(clickedElement).data('value'));
    this.currentOption = $(clickedElement).data('value')
  }
}