
import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { CairoService } from './cairo.service';
import { AuthService } from '../auth/auth.service';




@Component({
    selector: 'fittings-customer-selector',
    template: `
        <select class="custom-select mb-3 select-horizons" (change)="onChange($event)">
            <option *ngFor="let customer of customers">{{ customer }}</option>
        </select>
    `
})
export class CustomerSelectorComponent implements OnInit {

    @Output() onSelection = new EventEmitter<string>();

    constructor(private srv: AuthService) { }
    private customers: string[] = [];

    ngOnInit() {
        this.customers = this.srv.shops_list();
        if (this.customers.length > 0) {
            this.onSelection.emit(this.customers[0]);
        } else {
            this.onSelection.emit(this.srv.shop_id())
        }
    }

    private onChange(event) {
        let selector = event.target || event.srcElement;
        let customer = selector.options[selector.selectedIndex].value;
        this.onSelection.emit(customer);
    }
}


@Component({
    selector: 'fittings-machine-selector',
    template: `
        <select class="custom-select mb-3 select-horizons" (change)="onChange($event)">
            <option *ngFor="let machine of machines" value="{{ machine.name }}">
                {{ machine.alias ? machine.alias : machine.name }}
            </option>
        </select>
    `
})
export class MachineSelectorComponent implements OnInit {

    @Output() onSelection = new EventEmitter<string>();

    constructor(private srv: CairoService, private auth: AuthService) { }
    private machines: any[] = [];

    ngOnInit() {
        let mycustomer = this.auth.shop_id();
        if (mycustomer) {
            this.load(mycustomer);
        }
    }

    public load(customer: string) {
        this.srv.machines(customer).then((m: any[]) => {
            this.machines=m;

            if (this.machines.length > 0) {
                this.onSelection.emit(this.machines[0].name);
            }
        });
    }

    private onChange(event) {
        let selector = event.target || event.srcElement;
        let machine = selector.options[selector.selectedIndex].value;
        this.onSelection.emit(machine);
    }
}


@Component({
    selector: 'fittings-recipe-selector',
    template: `
        <select class="custom-select mb-3 select-horizons" (change)="onChange($event)">
            <option *ngFor="let recipe of recipes" value="{{ recipe.name }}">
                {{ recipe.alias }}
            </option>
        </select>
    `
})
export class RecipeSelectorComponent {

    @Output() onSelection = new EventEmitter<string>();
    constructor(private srv: CairoService) { }
    private recipes: any[] = [];

    public load(customer: string, machine: string) {
        this.srv.recipes(customer, machine).then((r: any[]) => {
            this.recipes = r;
            if (this.recipes.length > 0) {
                this.onSelection.emit(this.recipes[0].name);
            }
        });
    }

    private onChange(event) {
        let selector = event.target || event.srcElement;
        let recipe = selector.options[selector.selectedIndex].value;
        this.onSelection.emit(recipe);
    }
}


@Component({
    selector: 'fittings-substrate-selector',
    template: `
        <select class="custom-select mb-3 select-horizons">
            <option *ngFor="let substrate of substrates">
                {{ substrate }}
            </option>
        </select>
    `
})
export class SubstrateSelectorComponent {

    constructor(private srv: CairoService) { }
    private substrates: string[] = [];

    public load(customer: string, machine: string, recipe: string) {
        this.srv.substrates(customer, machine, recipe).then((s: string[]) => {
            this.substrates = s;
        });
    }
}

@Component({
    selector: 'fittings-spectrophotometer',
    template: `
        <select class="custom-select mb-3 select-horizons">
            <option *ngFor="let spect of spectrophotometers" 
                value="{{ spect.id }}"
            >
                {{ spect.name }}
            </option>
        </select>
    `
})
export class SpectrophotometerComponent implements OnInit {

    constructor(private srv: CairoService) { }
    private spectrophotometers = [];

    ngOnInit() {
        this.spectrophotometers = this.srv.spectrophotometers();
    }
}

@Component({
    selector: '[value-pair-table]',
    template: `
        <th scope="row">{{ name }}</th>
        <td>
            <div class="units" [ngClass]='units'>
                <input id="{{id}}-old" [attr.readonly]="both_readonly ? true: null" class="form-control" type="number" value="{{ old_value }}">
                <span></span>
            </div>
        </td>
        <td>
            <div class="units" [ngClass]='units'>
                <input id="{{id}}-new" readonly class="form-control" type="number" value="{{ new_value }}">
                <span></span>
            </div>                    
        </td>
    `,
    styleUrls: ['./layers.components.css']
})
export class PairTableComponent {
    @Input() id: string;
    @Input() name: string;
    @Input() units: string = 'nm';
    @Input() both_readonly: boolean = false;
}

@Component({
    selector: 'layers-values',
    template: `
        <div *ngIf="error" class="card bg-warning mb-3" style="max-width: 18rem;">
            <div class="card-body">
                <h5 class="card-title">Warning</h5>
                <p class="card-text">Something went wrong<br>Please contact with 
                    <a href="mailto:coatings@horizonsoptical.com">coatings@horizonsoptical.com</a>
                </p>
            </div>
        </div>    
        <table *ngIf="!error" class="table table-hover">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">current</th>
                    <th scope="col">target</th>
                </tr>
            </thead>                
            <tbody>
                <tr *ngFor="let layer of make_array(); let i = index;" value-pair-table name="{{ layers[i] }}" id="{{i}}"></tr>
                <tr [class.d-none]="!result" value-pair-table id="Rvis" name="Rvis" units="percentage" both_readonly="true"></tr>
                <tr [class.d-none]="!result" value-pair-table id="L" name="L" units="" both_readonly="true"></tr>
                <tr [class.d-none]="!result" value-pair-table id="Chroma" name="Chroma" units="" both_readonly="true"></tr>
                <tr [class.d-none]="!result" value-pair-table id="Hue" name="Hue" units="degree" both_readonly="true"></tr>
            </tbody>
        </table>
    `,
    styleUrls: ['./layers.components.css']
})
export class TableValuesComponent {
    private layers: string[] = [];

    private make_array(): any[] {
        return Array(this.layers.length);
    }    

    constructor(private srv: CairoService) { }
    public load(customer: string, machine: string, recipe: string) {
        this.srv.layers(customer, machine, recipe).then((layers: string[]) => {
            this.layers = layers;
        });
    }

    public get_layers() {
        return this.layers.map((name, idx) => {
            return $('#' + idx + '-old')[0].value;
        })
    }

    private readonly DecimalsMap = {
        Rvis: 2,
        L: 1,
        Chroma: 1,
        Hue: 0,
    }    
    
    private error: boolean = false;
    private result : boolean = false;
    public show(results) {
        this.error = (results.debug.CSQ2 > (results.debug.CSQ1 * 0.75));

        results.layers.forEach((value, idx) => {
            $('#' + idx + '-new')[0].value = value;
        });

        this.result = true;

        results.parameters.forEach(param => {
            let key = param.key;
            let value = param.value
            if (key in this.DecimalsMap) {
                value = Math.round(value * Math.pow(10, this.DecimalsMap[key])) /  Math.pow(10, this.DecimalsMap[key]);
            }
            $('#' + key + '-old')[0].value = value;
        });

        results.target_parameters.forEach(param => {
            let key = param.key.substr(1);
            let value = param.value
            if (key in this.DecimalsMap) {
                value = Math.round(value * Math.pow(10, this.DecimalsMap[key])) /  Math.pow(10, this.DecimalsMap[key]);
            }
            $('#' + key + '-new')[0].value = value;            
        });        
    }
}
